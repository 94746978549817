import { AppVisibility } from 'ui/enums';
import { OffPlatformTeamMember, TeamMembersSummary } from 'ui/types/teams';

import { IEcosystem } from './ecosystem';
import { IUserProfile } from './user';

enum TeamType {
  TeamEvents = 'TeamEvents',
}

export enum TeamMemberStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
}

export interface ITeam {
  _id: string;
  name: string;
  type: TeamType;
  leader: string;
  leaderSubDocument: IUserProfile;
  members: string[];
  membersSubDocument: IUserProfile[];
  membersStatuses: {
    [key: string]: string; //pending | approved etc
  };
  ecosystem: string | IEcosystem;
  ecosystemSubDocument: IEcosystem;
  deleted?: boolean;
  // Static team public invite
  publicInviteTeamSummary?: {
    _id: string;
    inviteURL: string;
    totalInvitesUsed?: number;
  };
  publicInviteId?: string;
  offPlatformMembers?: OffPlatformTeamMember[];
  membersSummary: { [key: string]: TeamMembersSummary };
  activity: string;
}

export interface ITeamPopulateEcosystem extends Omit<ITeam, 'ecosystem'> {
  ecosystem: IEcosystem;
}

export interface IMemberValue {
  value: string;
  label: string;
  email: string;
  profileImage?: string;
  isExisting?: boolean;
  __isNew__?: boolean;
}

export interface ITeamInviteData {
  team: string;
  memberUserProfile?: string;
  code: string;
  receiverEmail: string;
  isUsed: boolean;
  inviteURL: string;
  visibility?: AppVisibility;
  activity?: string;
}
