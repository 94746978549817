import { IEcosystem } from './interfaces/ecosystem';
import { UserProfile } from './user';

enum TeamType {
  TeamEvents = 'TeamEvents',
}

export enum TeamMemberStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
}

export interface Team {
  _id: string;
  name: string;
  type: TeamType;
  expectedTeamSize: number;
  leader: string;
  leaderSubDocument: UserProfile;
  members: string[];
  membersSubDocument: UserProfile[];
  membersStatuses: { [key: string]: string };
  ecosystem: string | IEcosystem;
  ecosystemSubDocument: IEcosystem;
  deleted?: boolean;
  publicInviteTeamSummary?: {
    _id: string;
    inviteURL: string;
    totalInvitesUsed?: number;
  };
  publicInviteId?: string;
  offPlatformMembers?: OffPlatformTeamMember[];
  membersSummary: { [key: string]: TeamMembersSummary };
}

export interface OffPlatformTeamMember {
  _id: string;
  email: string;
  status?: TeamMemberStatus;
  name?: string;
}

// This is a sub document that will be implemented gradually, to override membersStatuses
export interface TeamMembersSummary {
  createdAt?: Date;
  updatedAt?: Date;
  status: TeamMemberStatus;
}

export interface TeamMember {
  _id: string;
  email: string;
  name?: string;
  profilePicture?: string;
  status: TeamMemberStatus;
  joinedAt?: Date;
  showDeleteMemberButton?: boolean;
  onDeleteMember?: () => void;
  isInternalMember?: boolean;
}
